
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";

@Options({
    name: "flowable-nodes-trigger-node",
    emits: ["selected", "delNode", "insertNode"],
})
export default class TriggerNode extends NodesMinxins {
    private showError = false;
    private errorInfo = "";

    get content() {
        //return this.config;
        return "";
    }
    //校验数据配置的合法性
    public validate(err: any) {
        this.showError = false;
        if (this.config.props.type === "WEBHOOK") {
            if (!this.$tools.isEmpty(this.config.props.http.url)) {
                this.showError = false;
            } else {
                this.showError = true;
                this.errorInfo = "请设置WEBHOOK的URL地址";
            }
        } else if (this.config.props.type === "EMAIL") {
            if (this.$tools.isEmpty(this.config.props.email.subject) || this.config.props.email.to.length === 0 || this.$tools.isEmpty(this.config.props.email.content)) {
                this.showError = true;
                this.errorInfo = "请设置邮件发送配置";
            } else {
                this.showError = false;
            }
        }
        if (this.showError) {
            err.push(`${this.config.name} 触发动作未设置完善`);
        }
        return !this.showError;
    }
}
